import React, {useState, useEffect, useContext} from 'react';
import useLabels from '@hooks/useLabels';

// TODO Remove when sensor API is up.
const TMP_SENSOR_RESPONSE = {
  t1: '-',
  hyg1: '-',
  pluv: '-',
  hum: '-',
  date_mesure: '',
};

const Weather = (props: weatherParameters) => {
  const URL_API_OPENWEATHERMAP = `https://api.openweathermap.org/data/2.5/weather?lat=${props.lat}&lon=${props.lon}&appid=${props.owmApiKey}`;
  const URL_SENSOR =
    'https://www.webcomsag.fr/plugins/ws/?id=771&token=8d619bdc831d37a9f6084bfb782c33da';

  const {labels} = useLabels();
  const [iconName, setIconName] = useState(undefined);
  const [sensorData, setSensorData] = useState(TMP_SENSOR_RESPONSE); // TODO Change for "useState(undefined);" when sensor API is up

  useEffect(() => {
    // Open Weather Map (weather icon)
    fetch(URL_API_OPENWEATHERMAP)
      .then(response => response.json())
      .then(data => {
        if (data.weather && Array.isArray(data.weather)) {
          let weatherIcon = data.weather[0].icon; //'icon' value is three char (two digits + "d" or "n" if day or night).
          let iconName = weatherIcon.slice(0, -1); // Removing last char in order to keep only the first two digits (icon name = XX.svg in /static/img/weather/openweathermap )
          setIconName(iconName);
        }
      })
      .catch(e => console.error("Can't retrieve OpenWeatherMap data.", e));

    // Edmus sensor (weather details)
    fetch(URL_SENSOR)
      .then(response => response.json())
      .then(data => setSensorData(data))
      .catch(e => console.error("Can't retrieve Edmus sensor data.", e));
  }, []);

  return (
    <React.Fragment>
      <div className='mid-title force-space-bottom-5'>
        {labels.ACTUALLY_EDMUS}
        <div className='sub-line'></div>
      </div>

      <div className='weather'>
        <div className='column'>
          <div className='box-fill flex-col fill-height title mb-15'>
            <div>{labels.WEATHER_TITLE}</div>
            {sensorData.date_mesure}
          </div>
          <div className='box'>
            {iconName && <div className={`weather-icon-${iconName}`} />}
            <strong className='dflex content-center items-center'>
              {sensorData.t1}°C
            </strong>
          </div>
        </div>
        <div className='column content'>{props.children}</div>
        <div className='dflex flex-col'>
          <div className='box flex-col mb-15 flex-auto'>
            {labels.WEATHER_RAINFALL}
            <div className='sensor-icon-pluviometrie' />
            <strong>{sensorData.pluv}mm</strong>
          </div>
          <div className='box flex-col mb-15 flex-auto'>
            {labels.WEATHER_HUMIDITY}
            <div className='sensor-icon-hygrometrie' />
            <strong>{sensorData.hyg1}%</strong>
          </div>
          <div className='box flex-col flex-auto'>
            {labels.WEATHER_MOISTENING}
            <div className='sensor-icon-humectation' />
            <strong>{sensorData.hum}%</strong>
          </div>
        </div>

        {/* <div className='mid-title force-space-bottom-5'>
                {labels.ACTUALLY_EDMUS}
                <div className='sub-line'></div>
            </div>

            {iconName &&
                <div className="text-center">
                    <div className={`weather-icon-${iconName}`} />
                </div>
            }

            {sensorData &&
                <div className="text-center">
                    <div>{labels.WEATHER_HOUR_SAMPLE} : <strong>{sensorData.date_mesure}</strong></div>
                    <div className="weather container space-bottom-10">
                        <div className="columns ">
                            <div className="column col-lg-12 space-top-10">
                                <div className="columns">
                                    <div className="column col-lg-6 ">
                                        <div className="show-lg">
                                            <div className="sensor-icon-temperature" />
                                        </div>
                                        <div className="hide-lg sensor-icon-temperature" />{labels.WEATHER_TEMPERATURE} : <strong>{sensorData.t1}°C</strong>
                                    </div>
                                    <div className="column col-lg-6">
                                        <div className="show-lg">
                                            <div className="sensor-icon-pluviometrie" />
                                        </div>
                                        <div className="hide-lg sensor-icon-pluviometrie" />{labels.WEATHER_RAINFALL} : <strong>{sensorData.pluv}mm</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="column col-lg-12 space-top-10">
                                <div className="columns">
                                    <div className="column col-lg-6">
                                        <div className="show-lg">
                                            <div className="sensor-icon-hygrometrie" />
                                        </div>
                                        <div className="hide-lg sensor-icon-hygrometrie" />{labels.WEATHER_HUMIDITY} : <strong>{sensorData.hyg1}%</strong>
                                    </div>
                                    <div className="column col-lg-6">
                                        <div className="show-lg">
                                            <div className="sensor-icon-humectation" />
                                        </div>
                                        <div className="hide-lg sensor-icon-humectation" />{labels.WEATHER_MOISTENING} : <strong>{sensorData.hum}%</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
      }*/}
      </div>
    </React.Fragment>
  );
};
export default Weather;

type weatherParameters = {
  owmApiKey: string;
  lat: string;
  lon: string;
  children: React.ReactNode;
};
