import React, {useState, useEffect, useContext} from 'react';
import {graphql, Link, navigateTo} from 'gatsby';
import {RichText} from 'prismic-reactjs';
import Layout from '@components/layout';
import SEO from '@components/seo';
import Video from '@components/video';
//import NewsDisplayer from '@components/news-displayer';
import EdmusContext from '@components/application.provider';
import VideoPopup from '@components/video-popup';
import useLabels from '@hooks/useLabels';
import {HomeType} from 'src/models/Home.type';
import Weather from '@components/weather';

export const query = graphql`
  {
    prismic {
      allHomes {
        edges {
          node {
            introduction
            introductionLink
            _meta {
              uid
              lang
            }
            timelaps
            timelapsImage
            timelapsButtonLabel
            title
            video
            videoImage
          }
        }
      }
    }
  }
`;

const IndexPage = (props: any) => {
  const {language} = useContext(EdmusContext);
  const {labels} = useLabels();
  const {allHomes} = props.data.prismic;
  const [showVideo, setShowVideo] = useState<boolean>(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embedsocial.com/cdn/ht.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  const doc: HomeType = allHomes.edges.find(
    (docs: HomeType) => docs.node._meta.lang.slice(0, 2) === language
  );

  if (!doc) return null;
  return (
    <Layout>
      <div className='home'>
        <SEO title={RichText.asText(doc.node.title)} />
        <Video video={doc.node.video} image={doc.node.videoImage} />

        <div className='sub-container space-top-30 space-bottom-40 text-center text-orange'>
          <div className='home-intro'>{doc.node.introduction}</div>
          <Link to={doc.node.introductionLink}>({labels.KNOW_MORE})</Link>
        </div>

        <Weather
          owmApiKey='8a7fcfad85b4b441f1bcdca4b56c9c8a' /* TODO : Generate a new API KEY on OpenWeatherMap.com */
          lat='44.8786849'
          lon='-0.1920195'
        >
          <React.Fragment>
            <div>
              <div className='video'>
                {showVideo && (
                  <VideoPopup
                    setShowVideo={(v: boolean) => setShowVideo(v)}
                    video={doc.node.timelaps}
                  />
                )}

                <div
                  className='preview-container'
                  style={{
                    backgroundImage: `url('${doc.node.timelapsImage?.url}')`,
                  }}
                >
                  <div
                    className='play play-2x'
                    onClick={() => setShowVideo(true)}
                  />
                  <div
                    className='video-label'
                    onClick={() => setShowVideo(true)}
                  >
                    {doc.node.timelapsButtonLabel}
                  </div>
                </div>
              </div>
            </div>
            <div
              className='box-fill items-center'
              onClick={() => navigateTo('/timelapses-history')}
            >
              <Link className='timelapses-link' to='/timelapses-history'>
                {labels.PREVIOUS_DAY}
              </Link>
            </div>
          </React.Fragment>
        </Weather>

        {/* <NewsDisplayer /> */}

        <div className='mid-title force-space-top-35 force-space-bottom-5'>
          {labels.INSTAGRAM}
          <div className='sub-line'></div>
        </div>

        <div
          className='embedsocial-hashtag'
          data-ref='3973400ed452ceb6b5a26c2de268c8d111ef8f3b'
        ></div>
      </div>
    </Layout>
  );
};

export default IndexPage;
