import React, { useState } from 'react'
import Preview from './../static/img/video-preview.jpg';
import { PrismicVideo, PrismicImage } from 'src/models/Prismic.types';
import VideoModal from './video-popup';

const Video = (props: videoParameters) => {
    const { video } = props;
    const { image } = props;
    let url: any;

    const [showVideo, setShowVideo] = useState<boolean>(false);


    if (!video) return null;
    if (!image) url = Preview;
    return (
        <div className={`video ${props.className ? props.className : ''}`}>
            {
                showVideo &&
                <VideoModal setShowVideo={(v: boolean) => setShowVideo(v)} video={video} />
            }

            <div className='preview-container' onClick={() => setShowVideo(true)} >
                <img src={image ? image.url : url} alt={image ? image.alt : ''} className='img-responsive' />
                <div className='play' />
            </div>
        </div >
    )

}
export default Video

type videoParameters = {
    video?: PrismicVideo,
    image?: PrismicImage,
    className?: string
}

