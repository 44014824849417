import React, { useState, useEffect, Fragment, MouseEvent } from 'react';
import { PrismicVideo } from 'src/models/Prismic.types';

const VideoPopup = (props: {
  setShowVideo: (v: boolean) => void;
  video: PrismicVideo | string;
}) => {
  const [isPip, setIsPip] = useState<PipType>('');

  useEffect(() => {
    if (isPip === '') {
      const onScroll = (e: Event) => {
        setIsPip('pip');
      };
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  const addAutoplay = (html: any) => {
    try {
      const src = html.match(/src\s*=\s*\\*"(.+?)\\*"\s*/)[1];
      return html.replace(src, `${src}&autoplay=1`);
    } catch (error) {
      console.log(error);
      return html;
    }
  };

  return (
    <Fragment>
      <div className={`overlay ${isPip}`}>
        <div className={`bg ${isPip}`}></div>
      </div>
      <div className={`video-popup ${isPip}`}>
        <div
          className='full-width text-right'
          onClick={() => props.setShowVideo(false)}
        >
          <i className={` close icon icon-cross  ${isPip}`}></i>
        </div>
        {typeof props.video === 'string' ? (
          <div className='pane video'>
            <video controls autoPlay>
              <source src={props.video} />
            </video>
          </div>
        ) : (
            <div
              className='pane video-responsive'
              dangerouslySetInnerHTML={{ __html: addAutoplay(props.video.html) }}
            />
          )}
      </div>
    </Fragment>
  );
};
export default VideoPopup;

export type PipType = 'pip' | '';
